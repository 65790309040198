import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import "./component.css";

const HargaJual = () => {
  const { user } = useSelector((state) => state.auth);
  const [perusahaan, setPerusahaan] = useState([]);

  useEffect(() => {
    getPerusahaan();
  }, []);

  const getPerusahaan = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `https://c-greenproject.org:8000/perusahaan/?t=${new Date().getTime()}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Data fetched from API:", response.data);
      // Pastikan data adalah array
      if (Array.isArray(response.data)) {
        setPerusahaan(response.data);
      } else {
        console.error("Data tidak dalam format array:", response.data);
      }
    } catch (error) {
      console.error("Error fetching perusahaan data:", error);
      alert(`Error: ${error.response ? error.response.data.msg : error.message}`);
    }
  };

  useEffect(() => {
    console.log("Perusahaan state updated:", perusahaan);
  }, [perusahaan]);

  const formatRupiah = (harga) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return formatter.format(harga);
  };

  // Ambil data perusahaan terbaru
  const getLatestPerusahaanData = () => {
    if (perusahaan.length === 0) return {};
    
    // Mengasumsikan bahwa data terbaru ada di item terakhir
    const latestData = perusahaan[perusahaan.length - 1]; // Ambil item terakhir
    return latestData;
  };

  const perusahaanData = getLatestPerusahaanData();

  return (
    <Card className="mb-4 card-welcome">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4>
            <strong>Harga Jual</strong>
          </h4>
          <small className="text-muted">
            {`Tanggal Update: ${perusahaanData.tanggalupdateharga || "-"}`}
          </small>
        </div>

        <div>
          {["A", "B", "C"].map((grade) => (
            <Card key={grade} className="mb-3">
              <Card.Body className="p-3 card-detail-harga">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="fw-bold mb-1">{`Grade ${grade}`}</h6>
                    <p className="mb-0 small">
                      {perusahaanData[`catatanGrade${grade}`] || "-"}
                    </p>
                  </div>
                  <h5 className="fw-bold mb-0">
                    {perusahaanData[`hargaGrade${grade}`]
                      ? formatRupiah(perusahaanData[`hargaGrade${grade}`])
                      : "-"}
                  </h5>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
        {user && user.role === "perusahaan" && (
          <Link to="/update-harga" className="btn btn-primary btn-sm">
            Update Harga Jual
          </Link>
        )}
      </Card.Body>
    </Card>
  );
};

export default HargaJual;