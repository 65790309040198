import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const HargaChart = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    fetchDataForChart();
  }, []);

  const fetchDataForChart = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://c-greenproject.org:8000/hargaforchart",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      const formatRupiah = (harga) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(harga);
      };

      const labels = data.map((item) => item.tanggalupdateharga);
      const hargaGradeA = data.map((item) => formatRupiah(item.hargaGradeA));
      const hargaGradeB = data.map((item) => formatRupiah(item.hargaGradeB));
      const hargaGradeC = data.map((item) => formatRupiah(item.hargaGradeC));

      setChartData({
        labels,
        datasets: [
          {
            label: "Harga Grade A",
            data: data.map((item) => item.hargaGradeA),
            borderColor: "rgb(31, 80, 154)",
            backgroundColor: "rgb(31, 80, 154)",
            tension: 0.4,
          },
          {
            label: "Harga Grade B",
            data: data.map((item) => item.hargaGradeB),
            borderColor: "rgb(255, 204, 29)",
            backgroundColor: "rgb(255, 204, 29)",
            tension: 0.4,
          },
          {
            label: "Harga Grade C",
            data: data.map((item) => item.hargaGradeC),
            borderColor: "rgb(60, 207, 78)",
            backgroundColor: "rgb(60, 207, 78)",
            tension: 0.4,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching chart data:", error.message);
    }
  };

  return (
    <div className="chart-container">
      <h4 className="fw-bold mb-1 pt-4 text-center">
        <strong>Grafik Harga Jual</strong>
      </h4>
      {chartData ? (
        <Line
          className="pb-3 mb-2"
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
              },
              title: {
                display: true,
                text: "Perubahan Harga Berdasarkan Tanggal",
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const value = context.raw;
                    return `Rp ${new Intl.NumberFormat("id-ID").format(value)}`;
                  },
                },
              },
            },
          }}
        />
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
};

export default HargaChart;
